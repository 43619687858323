import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import UnstyledLink from "./UnstyledLink";
import BaseContentBox from "./BaseContentBox";

type RecipeBoxProps = {
  title: string;
  slug: string;
  image: {
    childImageSharp: {
      fluid: {
        base64: string;
        aspectRatio: number;
        src: string;
        srcSet: string;
        sizes: string;
      };
    };
  };
};

const StyledRecipeBox = styled(BaseContentBox)`
  padding: 2em 3em;
  padding-bottom: 0;
  border-bottom: 8px solid #d63d4a;
`;

const RecipeTitle = styled.h4`
  font-weight: ${(props) => props.theme.fonts.weights.regular};
  text-align: center;
  margin: 1.5em 0;
  line-height: 1.6em;
  color: hsl(0, 0%, 10%);
`;

const RecipeBox: React.FC<RecipeBoxProps> = ({ image, title, slug }) => {
  return (
    <UnstyledLink to={`/recipe/${slug}`}>
      <StyledRecipeBox>
        <Img fluid={{ ...image.childImageSharp.fluid, aspectRatio: 1 }} />
        <RecipeTitle>{title}</RecipeTitle>
      </StyledRecipeBox>
    </UnstyledLink>
  );
};

export default RecipeBox;
