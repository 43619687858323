import styled from "styled-components";

const BaseContentBox = styled.div`
  height: 100%;
  box-sizing: border-box;
  box-shadow: 0 4px 6px 0 ${(props) => props.theme.colors.text};
  border-radius: 5px;
`;

export default BaseContentBox;
