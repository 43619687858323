import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import RecipeBox from "./components/common/RecipeBox";
import RecipesIcon from "./assets/svgs/icon-inbox-full.svg";
import PageWithContentBoxes from "./components/common/PageWithContentBoxes";

type RecipeNode = {
  frontmatter: {
    title: string;

    image: {
      childImageSharp: {
        fluid: {
          base64: string;
          aspectRatio: number;
          src: string;
          srcSet: string;
          sizes: string;
        };
      };
    };
  };
  fields: {
    slug: string;
  };
};

type RecipesResponse = {
  recipes: {
    nodes: RecipeNode[];
  };
};

const Recipes = () => {
  const data = useStaticQuery<RecipesResponse>(graphql`
    {
      recipes: allMdx(
        filter: { slug: { glob: "recipes/*" } }
        sort: { fields: frontmatter___published, order: DESC }
      ) {
        nodes {
          frontmatter {
            title
            image {
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  `);

  return (
    <PageWithContentBoxes title="Recipes" icon={<RecipesIcon />}>
      {data.recipes.nodes.map((node) => {
        const recipe = {
          title: node.frontmatter.title,
          slug: node.fields.slug,
          image: node.frontmatter.image,
        };

        return <RecipeBox key={recipe.slug} {...recipe} />;
      })}
    </PageWithContentBoxes>
  );
};

export default Recipes;
