import React from "react";
import styled from "styled-components";
import Header from "./components/common/Header";
import Seo from "./components/common/Seo";

const StyledLayout = styled.div`
  position: relative;
`;

type LayoutProps = {
  title: string;
  path: string;
  description?: string;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  title,
  path,
  description,
}) => {
  return (
    <>
      <Seo title={title} path={path} description={description} />
      <StyledLayout>
        <Header />
        <main>{children}</main>
      </StyledLayout>
    </>
  );
};

export default Layout;
