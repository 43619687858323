import React from "react";
import styled from "styled-components";

const StyledPageWithContentBoxes = styled.main`
  padding: 1em 1.5em;
  margin: auto;
  @media screen and (min-width: 768px) {
    padding: 1em 2em;
    width: 600px;
  }
  @media screen and (min-width: 1440px) {
    width: 1000px;
  }
`;

const PageTitle = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5em;
    height: 32px;
    width: auto;
    .primary {
      fill: hsl(0, 0%, 45%);
    }
    .secondary {
      fill: hsl(0, 0%, 70%);
    }
  }
`;

const ContentBoxesContainer = styled.div`
  padding: 1em 0em;
  display: grid;
  grid-gap: 3em;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  @media screen and (min-width: 768px) {
    padding: 2em 0em;
  }
  @media screen and (min-width: 1024px) {
    grid-gap: 4em;
  }
`;

type PageWithContentBoxesProps = {
  title: string;
  icon?: React.ReactNode;
};

const PageWithContentBoxes: React.FC<PageWithContentBoxesProps> = ({
  title,
  icon,
  children,
}) => {
  return (
    <StyledPageWithContentBoxes>
      <PageTitle>
        {icon}
        <h2>{title}</h2>
      </PageTitle>
      <ContentBoxesContainer>{children}</ContentBoxesContainer>
    </StyledPageWithContentBoxes>
  );
};

export default PageWithContentBoxes;
