import React from "react";
import Page from "../components/Page";
import Recipes from "../Recipes";

type RecipesPageProps = {
  location?: {
    pathname?: string;
  };
};

const RecipesPage: React.FC<RecipesPageProps> = ({ location }) => {
  return (
    <Page title="Recipes" path={location?.pathname}>
      <Recipes />
    </Page>
  );
};

export default RecipesPage;
