import React from "react";
import { Helmet } from "react-helmet";
import generatePageTitle from "./generatePageTitle";
import Layout from "../../Layout";

type PageProps = {
  title: string;
  path?: string;
};

const Page: React.FC<PageProps> = ({ title, path, children }) => {
  const pageTitle = generatePageTitle(title);
  return (
    <>
      <Helmet title={pageTitle} />
      <Layout title={pageTitle} path={path ?? ""}>
        {children}
      </Layout>
    </>
  );
};

export default Page;
